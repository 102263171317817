import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { ApiConfig } from "../../../api.config";
import { Router } from "@angular/router";
import { catchError } from "rxjs/operators";
import { INTERCEPTOR } from "../../admin/model/constants";
import { NotificationService } from "../../shared/services/notification.service";

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  constructor(private router: Router, private ntf: NotificationService) {}

  private errorMessages: { [key: string]: string } = {
    product_barcode_already_exists: "Штрихкод уже существует",
    "non-cash-account-balance-less-than-request":
      "Баланс безналичного счета меньше запрашиваемой суммы",
    "store-balance-less-than-request": "Запрашиваемая сумма больше фактической",
    "product-not-found": "Продукт не найден",
    currency_course_not_found: "Курс валюты не найден",
    "product-post-info-not-found": "Информация о продукте на найдено",
    dic_currency_not_found: "Валюта не найдена",
    "product-imei-not-sold-more-than-one":
      "Продукт не был продан более чем один раз",
    order_not_found: "Заказ не найден",
    unique_issue: "Ошибка в уникальности объекта",
    not_nullable: "Параметр не может быть пустым",
    "client-duty-can-repayment-only-owner-store-or-admin":
      "Погашать задолженность клиента могут только пользователи этого магазина или админы",
    "user-can-sale-only-own-product":
      "Пользователь может продавать только свои продукты",
    "product-sold-return-before-delete":
      "Продукт продан, сделайте возврат прежде чем удалить товар",
    "client-duty-not-found":
      "Данные о долгов клиента этому магазину не найдены",
    "duty-sum-less-than-request": "Общая сумма долга меньше запрашиваемой",
    "provider-not-found": "Провайдер не найден",
    "product-have-active-request": "Продукт имеет активный запрос",
    "dic-value-not-found":
      "Запрашиваемое значение справочника не найдено в системе",
    "illegal-argument-exception": "Переданные параметры неправильны",
    "main-store-not_found": "Главный магазин не найден",
    "store-not_found": "Магазин не найден",
    "main-store-not-send-to-central":
      "Основной магазин не может передать самому себе",
    "balance-transfer-history-not-found":
      "История перемещения средств не найден",
    "balance-transfer-canceled": "Передача средств уже был отменен",
    "store-balance-null": "Значение баланса 0",
    "user-can-return-only-own-product":
      "Пользователь может делать возврат только свои продукты",
    "store-duty-own-error": "В долг можно дать только другим магазинам",
    "product-transfer-task-not-found": "Перемещение продукта не найден",
    "seller-can-transfer-only-own-product":
      "Продавец может перемещать только свои продукты",
    "product-transfer-status-new":
      "Перемещаемый продукт не должен быть проданным",
    "product-transfer-error": "Ошибка в перемещения товара связано с правами",
    "product-imei-not-sold-exists": "Продукт уже существует",
    cash_duty_sum_less_than_request: "Общий денежный долг меньше запрашиваемой",
    product_duty_sum_less_than_request:
      "Общий товарный долг меньше запрашиваемой",
    "product-sale-returned-false-more-than-one-for-product-id":
      "Продукт не был возвращен более одного раза",
  };

  private addAuthHeader(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes(ApiConfig.api + "/oauth/token")) {
      return next.handle(request);
    }

    request = this.addAuthHeader(request);

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          localStorage.clear();
          this.router.navigate(["/signin"], {
            queryParams: {
              from: INTERCEPTOR,
              status: "401",
            },
          });
        } else if (error.status === 500) {
          this.ntf.showNotification(
            "snackbar-danger",
            "Ошибка на стороне сервера, обратитесь в тех.поддержку"
          );
        } else if (error.status === 400 && error.error?.errorCode) {
          console.log(error.error.errorCode);
          console.log(this.errorMessages[error.error.errorCode]);

          const msg =
            this.errorMessages[error.error.errorCode] ||
            "Произошла ошибка. Пожалуйста, попробуйте еще раз.";

          this.ntf.showNotification("snackbar-danger", msg);
        } else {
          this.ntf.showNotification("snackbar-danger", "Ошибка");
        }

        return throwError(error);
      })
    );
  }
}
