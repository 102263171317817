import { map, of, Observable } from "rxjs";
import { SettingsService } from "./../services/settings.service";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "currencySymbol",
})
export class CurrencySymbolPipe implements PipeTransform {
  constructor(private siteSettings: SettingsService) {}

  transform(currencyCode: string | undefined): Observable<string> {
    if (!currencyCode) return of("");

    switch (currencyCode) {
      case "TNG":
        return this.siteSettings.siteSettings$.pipe(
          map((settings) => settings.mainCurrencySymbol)
        );

      case "USD":
        return of("$");

      default:
        return of(currencyCode);
    }
  }
}
