import { map, Observable, of } from "rxjs";
import { SettingsService } from "./../services/settings.service";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "currencyName",
})
export class CurrencyNamePipe implements PipeTransform {
  constructor(private siteSettings: SettingsService) {}

  transform(code: string | undefined): Observable<string> {
    if (!code) return of("");

    switch (code) {
      case "TNG":
        return this.siteSettings.siteSettings$.pipe(
          map((settings) => settings.mainCurrencyName)
        );

      case "USD":
        return of("Доллар");

      default:
        return of(code);
    }
  }
}
