import { Store } from "./user";

export class Dictionary {
  id: number
  code: string
  nameKz: string  
  nameQq: string 
  nameRu: string  
  nameEn: string  
  referenceId?: number  
}

export class Person {
  address: string
  email: string
  firstName: string
  id: number
  lastName: string
  middleName: string
  telephone: string
  type?: { id: number; name: string; type: string }
  fullName: string
}

export class ExchangeRate {
  id: number
  actualDate: string
  value: string | number
  currency: Dictionary
}

export class Product {
  actualPrice: number
  color: Dictionary // цвет
  description: string // примечание
  id: number
  imei: string = ""; // имей код
  memory: Dictionary // память
  model: Dictionary // модель
  productPostings: Posting[] = [];
  family: Dictionary //семейство
  ram: Dictionary //
  state: string
  status: string
  store: Store
  provider: Dictionary
  product_type: string
  isDuty: boolean = false;
  price: number
  currency: Dictionary

  partDuty?: number | string;
  note?: string;
  dutyReturnDate?: Date | string;
  productSales?: Product[] = [];
  isSoldInDollar?: boolean;
}

export class Posting {
  currency: Dictionary
  description: string
  id: number
  isDuty: boolean = false;
  price: number
  productId: number
  provider: Person
}

export class ProductHistory {
  flowType: string
  id: number
  product: Product
  store: Store
  lastModifiedDate: string;
  sourceId: number;
  status: string;
  user: Person;
  oldValue?: number | string;
  newValue?: number | string;
}

export class ProductSaleHistory {
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string | null;
  lastModifiedDate: string | null;
  id: number;
  sales: Product[];
  client: Person;
  seller: Person;
  totalSum: number;
  description: string;
}

export interface ProdcutPostingHistory {
  createdBy: string;
  createdByPerson: Person;
  createdDate: string;
  currency: Dictionary;
  description: string;
  id: number;
  lastModifiedBy: string;
  lastModifiedDate: string;
  postings: Array<Posting>;
  provider: Person;
  totalSum: number;
}

export interface ProductReturnHistory {
  createdBy: string;
  createdDate: string;
  description: string;
  id: number;
  returns: Array<{
    description: string;
    id: number;
    productId: number;
    productSaleId: number;
    returnDateTime: string;
  }>;
}

export interface BarcodeSaveDto {
  code: string;
  colorId: number;
  id: number;
  memoryId: number;
  modelId: number;
  productTypeId: number;
  familyId: number;
}

export interface Barcode {
  code: string;
  color: Dictionary;
  id: number;
  memory: Dictionary;
  model: Dictionary;
  family: Dictionary;
  productType: Dictionary;
}
