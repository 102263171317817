import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  isLoading: boolean = false;
  autoAdition: boolean = true;

  constructor(private snackBar: MatSnackBar) {}

  showLoader() {
    this.isLoading = true;
  }

  hideLoader() {
    this.isLoading = false;
  }

  // show(message: string, res: 'success' | 'error' | 'warning', title: string) {
  //   this.matDialog.open(NotificationDialogComponent, {
  //     maxHeight: '45vh',
  //     minHeight: '25vh',
  //     minWidth: '25vw',
  //     maxWidth: '45vw',
  //     data: { result: message, res, status: title },
  //   });
  // }

  showNotification(
    colorName:
      | "snackbar-success"
      | "snackbar-warning"
      | "snackbar-danger"
      | "snackbar-info",
    text: string
  ) {
    this.snackBar.open(text, "", {
      duration: 2000,
      verticalPosition: "bottom",
      horizontalPosition: "right",
      panelClass: colorName,
    });
  }

  confirmSwal(
    title: string,
    text: string,
    icon: "warning" | "success" | "error" | "info" | "question",
    confirmText: string = "Да ",
    cancelText: string = "Нет"
  ) {
    return Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: true,
      // confirmButtonColor: '#3085d6',
      cancelButtonColor: "#d33",
      confirmButtonText: confirmText,
      cancelButtonText: cancelText,
    });
  }

  isConfirmed(
    title: string,
    text: string,
    icon: "warning" | "success" | "error" | "info" | "question"
  ) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
    });

    swalWithBootstrapButtons.fire(title, text, icon);
  }
}
