<div class="settingSidebar" [ngClass]="isOpenSidebar ? 'showSettingPanel' : ''">
  <a
    href="javascript:void(0)"
    class="settingPanelToggle"
    (click)="toggleRightSidebar()"
  >
    <i class="fa fa-spin fa-cog"></i>
  </a>
  <div
    class="settingSidebar-body ps-container ps-theme-default"
    [ngStyle]="{
      position: 'relative',
      'max-width': maxWidth + 'px'
    }"
  >
    <div class="fade show active">
      <div class="setting-panel-header">Настройки</div>
      <div class="p-15 border-bottom">
        <div class="exchange m-b-20 border-bottom">
          <div
            *ngIf="isAccessChangeExchange"
            class="exchange__logo"
            [ngClass]="{ 'bg-check': !isAccessChangeExchange }"
            (click)="editExchange()"
          >
            <i class="fas fa-pen"></i>
          </div>

          <div
            *ngIf="!isAccessChangeExchange"
            class="exchange__logo"
            [ngClass]="{ 'bg-check': !isAccessChangeExchange }"
            (click)="saveExchange()"
          >
            <i class="fas fa-check"></i>
          </div>

          <span style="font-weight: bold; font-size: 15px">$1 = </span>

          <input
            #exchange
            [(ngModel)]="adminService.currentCourse.value"
            [dropSpecialCharacters]="false"
            [disabled]="isAccessChangeExchange"
            type="text"
            mask="separator.2"
            thousandSeparator=""
            separatorLimit="99999999999999"
          />

          <p class="date-exchange">
            Посл. изменения была
            {{
              adminService.currentCourse.actualDate | date : "dd/MM/yyyy HH:mm"
            }}
          </p>
        </div>

        <form [formGroup]="form">
          <mat-form-field style="width: 100%" appearance="outline">
            <mat-label> Наименование валюты </mat-label>
            <input
              matInput
              type="text"
              formControlName="mainCurrencyName"
              placeholder="Пример: Доллар США"
            />
            <mat-error
              *ngIf="
                form.get('mainCurrencyName').hasError('required') &&
                form.get('mainCurrencyName').touched
              "
              >Обязательные поле</mat-error
            >
          </mat-form-field>

          <mat-form-field style="width: 100%" appearance="outline">
            <mat-label> Символ валюты </mat-label>

            <input
              matInput
              type="text"
              formControlName="mainCurrencySymbol"
              placeholder="Пример: $"
            />
            <mat-error
              *ngIf="
                form.get('mainCurrencySymbol').hasError('required') &&
                form.get('mainCurrencySymbol').touched
              "
              >Обязательные поле</mat-error
            >
          </mat-form-field>

          <div class="d-flex justify-content-end">
            <button
              type="button"
              mat-raised-button
              (click)="form.enable()"
              color="primary"
              *ngIf="form.disabled"
            >
              Редактировать
            </button>
            <button
              type="button"
              mat-raised-button
              (click)="saveSettings()"
              color="primary"
              *ngIf="form.enabled"
              [disabled]="form.status === 'INVALID'"
            >
              Сохранить
            </button>
          </div>
        </form>

        <div class="m-b-20 border-bottom" style="padding-bottom: 15px">
          <mat-slide-toggle [(ngModel)]="ntf.autoAdition" class="autoadd">
            <p class="links_name">Автодобавление</p>
          </mat-slide-toggle>
        </div>
        <h6 class="font-medium m-b-10">Выберите фон</h6>
        <div class="flex flex-wrap hiddenradio">
          <div class="flex flex-col">
            <label>
              <input
                type="radio"
                name="value"
                value="light"
                [checked]="isDarTheme == false ? true : false"
                (click)="lightThemeBtnClick()"
              />
              <img src="assets/images/light.png" />
            </label>
            <div class="mt-1 text-md text-center">Светлый</div>
          </div>
          <div class="flex flex-col mt-3">
            <label>
              <input
                type="radio"
                name="value"
                value="dark"
                [checked]="isDarTheme == true ? true : false"
                (click)="darkThemeBtnClick()"
              />
              <img src="assets/images/dark.png" />
            </label>
            <div class="mt-1 text-md text-center">Темный</div>
          </div>
        </div>
      </div>
      <div class="rightSetting">
        <h6 class="font-medium m-b-10">Цвет бокового меню</h6>
        <mat-button-toggle-group
          class="mt-2"
          [value]="isDarkSidebar ? 'dark' : 'light'"
        >
          <mat-button-toggle (click)="lightSidebarBtnClick()" value="light"
            >Светлый</mat-button-toggle
          >
          <mat-button-toggle (click)="darkSidebarBtnClick()" value="dark"
            >Темный</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
      <div class="p-15 border-bottom">
        <h6 class="font-medium m-b-10">Цвет темы</h6>
        <div class="theme-setting-options">
          <ul class="choose-theme list-unstyled mb-0">
            <li
              data-theme="white"
              [ngClass]="{ active: selectedBgColor === 'white' }"
              (click)="selectTheme('white')"
            >
              <div class="white"></div>
            </li>
            <li
              data-theme="black"
              [ngClass]="{ active: selectedBgColor === 'black' }"
              (click)="selectTheme('black')"
            >
              <div class="black"></div>
            </li>
            <li
              data-theme="purple"
              [ngClass]="{ active: selectedBgColor === 'purple' }"
              (click)="selectTheme('purple')"
            >
              <div class="purple"></div>
            </li>
            <li
              data-theme="orange"
              [ngClass]="{ active: selectedBgColor === 'orange' }"
              (click)="selectTheme('orange')"
            >
              <div class="orange"></div>
            </li>
            <li
              data-theme="cyan"
              [ngClass]="{ active: selectedBgColor === 'cyan' }"
              (click)="selectTheme('cyan')"
            >
              <div class="cyan"></div>
            </li>
            <li
              data-theme="green"
              [ngClass]="{ active: selectedBgColor === 'green' }"
              (click)="selectTheme('green')"
            >
              <div class="green"></div>
            </li>
            <li
              data-theme="blue"
              [ngClass]="{ active: selectedBgColor === 'blue' }"
              (click)="selectTheme('blue')"
            >
              <div class="blue"></div>
            </li>
          </ul>
        </div>
      </div>
      <!-- <div class="rightSetting">
        <h6 class="font-medium m-b-10">RTL Layout</h6>
        <mat-slide-toggle
          class="mt-2"
          [checked]="isRtl"
          (change)="switchDirection($event)"
        ></mat-slide-toggle>
      </div> -->
    </div>
  </div>
</div>
