import { MatDialog } from "@angular/material/dialog";
import { Router, NavigationEnd } from "@angular/router";
import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy,
  TemplateRef,
} from "@angular/core";
import { ROUTES } from "./sidebar-items";
import { AuthService } from "../../authentication/service/auth.service";
import { User } from "../../admin/model/user";
import { AdminService } from "../../admin/services/admin.service";
import { finalize, Subscription } from "rxjs";
import { SettingsService } from "../../shared/services/settings.service";
import { Settings } from "../../shared/models/base.model";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NotificationService } from "../../shared/services/notification.service";

declare const Waves: any;
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, OnDestroy {
  user: User = new User();
  public sidebarItems: any[];
  level1Menu = "";
  level2Menu = "";
  level3Menu = "";
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  headerHeight = 60;
  routerObj: Subscription;
  siteSettings: Settings;

  constructor(
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private router: Router,
    public adminService: AdminService,
    public siteSettingsService: SettingsService,
    
  ) {
    this.user = this.authService.getUser();
    this.getRouterEvents();
    this.getSettings();
  }

 
  getSettings() {
    this.siteSettingsService.siteSettings$.subscribe(
      (siteSettings) => (this.siteSettings = siteSettings)
    );
  }

  getRouterEvents() {
    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currenturl = event.url.split("?")[0];
        this.level1Menu = currenturl.split("/")[1];
        this.level2Menu = currenturl.split("/")[2];

        this.renderer.removeClass(document.body, "overlay-open");
      }
    });
  }

  @HostListener("window:resize", ["$event"])
  windowResizecall(event) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }

  @HostListener("document:mousedown", ["$event"])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(document.body, "overlay-open");
    }
  }
  callLevel1Toggle(event: any, element: any) {
    if (element === this.level1Menu) {
      this.level1Menu = "0";
    } else {
      this.level1Menu = element;
    }
    const hasClass = event.target.classList.contains("toggled");
    if (hasClass) {
      this.renderer.removeClass(event.target, "toggled");
    } else {
      this.renderer.addClass(event.target, "toggled");
    }
  }
  callLevel2Toggle(event: any, element: any) {
    if (element === this.level2Menu) {
      this.level2Menu = "0";
    } else {
      this.level2Menu = element;
    }
  }
  callLevel3Toggle(event: any, element: any) {
    if (element === this.level3Menu) {
      this.level3Menu = "0";
    } else {
      this.level3Menu = element;
    }
  }
  ngOnInit() {
    // if (this.authService.currentUserValue) {
    this.sidebarItems = ROUTES.filter((sidebarItem) => sidebarItem);
    // }
    this.initLeftSidebar();
    this.bodyTag = document.body;
  }
  ngOnDestroy() {
    this.routerObj.unsubscribe();
  }
  initLeftSidebar() {
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }
  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + "";
    this.listMaxWidth = "500px";
  }
  isOpen() {
    return this.bodyTag.classList.contains("overlay-open");
  }
  checkStatuForResize(firstTime) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(document.body, "ls-closed");
    } else {
      this.renderer.removeClass(document.body, "ls-closed");
    }
  }

  // mouseHover(e) {
  //   const body = this.elementRef.nativeElement.closest('body');

  //   if (body.classList.contains('submenu-closed')) {
  //     this.renderer.addClass(this.document.body, 'side-closed-hover');
  //     this.renderer.removeClass(this.document.body, 'submenu-closed');
  //   }
  // }
  // mouseOut(e) {
  //   const body = this.elementRef.nativeElement.closest('body');

  //   if (body.classList.contains('side-closed-hover')) {
  //     this.renderer.removeClass(this.document.body, 'side-closed-hover');
  //     this.renderer.addClass(this.document.body, 'submenu-closed');
  //   }
  // }
}
