import { SettingsService } from "./../../shared/services/settings.service";
import { DOCUMENT } from "@angular/common";
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  AfterViewInit,
  Renderer2,
  ChangeDetectionStrategy,
  ViewChild,
} from "@angular/core";
import { ConfigService } from "../../config/config.service";
import { RightSidebarService } from "../../core/service/rightsidebar.service";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { UnsubscribeOnDestroyAdapter } from "../../shared/UnsubscribeOnDestroyAdapter";
import { AdminService } from "../../admin/services/admin.service";
import { NotificationService } from "../../shared/services/notification.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { finalize } from "rxjs";
import { Settings } from "../../shared/models/base.model";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "app-right-sidebar",
  templateUrl: "./right-sidebar.component.html",
  styleUrls: ["./right-sidebar.component.scss"],
})
export class RightSidebarComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit, AfterViewInit
{
  selectedBgColor = "white";
  maxHeight: string;
  maxWidth: string;
  showpanel = false;
  isOpenSidebar: boolean;
  isDarkSidebar = false;
  isDarTheme = false;
  isRtl = false;
  public config: any = {};

  isAccessChangeExchange: boolean = true;
  currentCourse: any;
  @ViewChild("exchange", { read: ElementRef }) exchangeRef!: ElementRef;
  form: FormGroup = new FormGroup({
    id: new FormControl(null),
    mainCurrencyName: new FormControl(null, Validators.required),
    mainCurrencySymbol: new FormControl(null, Validators.required),
  });
  siteSettings: Settings;
   constructor(
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private rightSidebarService: RightSidebarService,
    private configService: ConfigService,
    public adminService: AdminService,
    public ntf: NotificationService,
    private settingsService: SettingsService,
    public siteSettingsService: SettingsService
  ) {
    super();
  }

  ngOnInit() {
    this.config = this.configService.configData;
    this.subs.sink = this.rightSidebarService.sidebarState.subscribe(
      (isRunning) => {
        this.isOpenSidebar = isRunning;
      }
    );
    this.setRightSidebarWindowHeight();
    this.getSettings();
    this.form.disable()
  }

  getSettings() {
    this.siteSettingsService.siteSettings$.subscribe((siteSettings) => {
      this.siteSettings = siteSettings;
      this.form.patchValue(this.siteSettings);
    });
  }

  

  saveSettings() {
    this.ntf.showLoader();
    this.siteSettingsService
      .updateSettings(this.form.value)
      .pipe(
        finalize(() => {
          this.ntf.hideLoader();
           this.form.disable();
        })
      )
      .subscribe((res) => {
        this.ntf.showNotification("snackbar-success", "Успешно обновлено");
        this.siteSettingsService.getSettings();
      });
  }

  ngAfterViewInit() {
    // set header color on startup
    if (localStorage.getItem("choose_skin")) {
      this.renderer.addClass(
        document.body,
        localStorage.getItem("choose_skin") || ""
      );
      this.selectedBgColor = localStorage.getItem("choose_skin_active")!;
    } else {
      this.renderer.addClass(
        document.body,
        "theme-" + this.config.layout.theme_color
      );
      this.selectedBgColor = this.config.layout.theme_color;
    }

    if (localStorage.getItem("menuOption")) {
      if (localStorage.getItem("menuOption") === "menu_dark") {
        this.isDarkSidebar = true;
      } else if (localStorage.getItem("menuOption") === "menu_light") {
        this.isDarkSidebar = false;
      } else {
        this.isDarkSidebar =
          this.config.layout.sidebar.backgroundColor === "dark" ? true : false;
      }
    } else {
      this.isDarkSidebar =
        this.config.layout.sidebar.backgroundColor === "dark" ? true : false;
    }

    if (localStorage.getItem("theme")) {
      if (localStorage.getItem("theme") === "dark") {
        this.isDarTheme = true;
      } else if (localStorage.getItem("theme") === "light") {
        this.isDarTheme = false;
      } else {
        this.isDarTheme = this.config.layout.variant === "dark" ? true : false;
      }
    } else {
      this.isDarTheme = this.config.layout.variant === "dark" ? true : false;
    }

    if (localStorage.getItem("isRtl")) {
      if (localStorage.getItem("isRtl") === "true") {
        this.setRTLSettings();
      } else if (localStorage.getItem("isRtl") === "false") {
        this.setLTRSettings();
      }
    } else {
      if (this.config.layout.rtl == true) {
        this.setRTLSettings();
      } else {
        this.setLTRSettings();
      }
    }
  }

  selectTheme(e) {
    this.selectedBgColor = e;
    const prevTheme = this.elementRef.nativeElement
      .querySelector(".settingSidebar .choose-theme li.active")
      .getAttribute("data-theme");
    this.renderer.removeClass(document.body, "theme-" + prevTheme);
    this.renderer.addClass(document.body, "theme-" + this.selectedBgColor);
    localStorage.setItem("choose_skin", "theme-" + this.selectedBgColor);
    localStorage.setItem("choose_skin_active", this.selectedBgColor);
  }
  lightSidebarBtnClick() {
    this.renderer.removeClass(document.body, "menu_dark");
    this.renderer.removeClass(document.body, "logo-black");
    this.renderer.addClass(document.body, "menu_light");
    this.renderer.addClass(document.body, "logo-white");
    const menuOption = "menu_light";
    localStorage.setItem("choose_logoheader", "logo-white");
    localStorage.setItem("menuOption", menuOption);
  }
  darkSidebarBtnClick() {
    this.renderer.removeClass(document.body, "menu_light");
    this.renderer.removeClass(document.body, "logo-white");
    this.renderer.addClass(document.body, "menu_dark");
    this.renderer.addClass(document.body, "logo-black");
    const menuOption = "menu_dark";
    localStorage.setItem("choose_logoheader", "logo-black");
    localStorage.setItem("menuOption", menuOption);
  }
  lightThemeBtnClick() {
    this.renderer.removeClass(document.body, "dark");
    this.renderer.removeClass(document.body, "submenu-closed");
    this.renderer.removeClass(document.body, "menu_dark");
    this.renderer.removeClass(document.body, "logo-black");
    if (localStorage.getItem("choose_skin")) {
      this.renderer.removeClass(
        document.body,
        localStorage.getItem("choose_skin")!
      );
    } else {
      this.renderer.removeClass(
        document.body,
        "theme-" + this.config.layout.theme_color
      );
    }

    this.renderer.addClass(document.body, "light");
    this.renderer.addClass(document.body, "submenu-closed");
    this.renderer.addClass(document.body, "menu_light");
    this.renderer.addClass(document.body, "logo-white");
    this.renderer.addClass(document.body, "theme-white");
    const theme = "light";
    const menuOption = "menu_light";
    this.selectedBgColor = "white";
    this.isDarkSidebar = false;
    localStorage.setItem("choose_logoheader", "logo-white");
    localStorage.setItem("choose_skin", "theme-white");
    localStorage.setItem("theme", theme);
    localStorage.setItem("menuOption", menuOption);
  }
  darkThemeBtnClick() {
    this.renderer.removeClass(document.body, "light");
    this.renderer.removeClass(document.body, "submenu-closed");
    this.renderer.removeClass(document.body, "menu_light");
    this.renderer.removeClass(document.body, "logo-white");
    if (localStorage.getItem("choose_skin")) {
      this.renderer.removeClass(
        document.body,
        localStorage.getItem("choose_skin")!
      );
    } else {
      this.renderer.removeClass(
        document.body,
        "theme-" + this.config.layout.theme_color
      );
    }
    this.renderer.addClass(document.body, "dark");
    this.renderer.addClass(document.body, "submenu-closed");
    this.renderer.addClass(document.body, "menu_dark");
    this.renderer.addClass(document.body, "logo-black");
    this.renderer.addClass(document.body, "theme-black");
    const theme = "dark";
    const menuOption = "menu_dark";
    this.selectedBgColor = "black";
    this.isDarkSidebar = true;
    localStorage.setItem("choose_logoheader", "logo-black");
    localStorage.setItem("choose_skin", "theme-black");
    localStorage.setItem("theme", theme);
    localStorage.setItem("menuOption", menuOption);
  }
  setRightSidebarWindowHeight() {
    const height = window.innerHeight - 60;
    this.maxHeight = height + "";
    this.maxWidth = "500px";
  }
  onClickedOutside(event: Event) {
    const button = event.target as HTMLButtonElement;
    if (button.id !== "settingBtn") {
      if (this.isOpenSidebar === true) {
        this.toggleRightSidebar();
      }
    }
  }
  toggleRightSidebar(): void {
    this.rightSidebarService.setRightSidebar(
      (this.isOpenSidebar = !this.isOpenSidebar)
    );
  }
  switchDirection(event: MatSlideToggleChange) {
    var isrtl: string = String(event.checked);
    if (
      isrtl === "false" &&
      document.getElementsByTagName("html")[0].hasAttribute("dir")
    ) {
      document.getElementsByTagName("html")[0].removeAttribute("dir");
      this.renderer.removeClass(document.body, "rtl");
    } else if (
      isrtl === "true" &&
      !document.getElementsByTagName("html")[0].hasAttribute("dir")
    ) {
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
      this.renderer.addClass(document.body, "rtl");
    }
    localStorage.setItem("isRtl", isrtl);
    this.isRtl = event.checked;
  }
  setRTLSettings() {
    document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    this.renderer.addClass(document.body, "rtl");
    this.isRtl = true;
    localStorage.setItem("isRtl", "true");
  }
  setLTRSettings() {
    document.getElementsByTagName("html")[0].removeAttribute("dir");
    this.renderer.removeClass(document.body, "rtl");
    this.isRtl = false;
    localStorage.setItem("isRtl", "false");
  }

  editExchange() {
    // if (this.user.role.code !== 'ADMIN') {
    //   this.ntf.openSnackBar('Доступен только админу', 'warn');
    //   return;
    // }
    this.isAccessChangeExchange = false;
    this.currentCourse = this.adminService.currentCourse.value;
    setTimeout(() => {
      this.exchangeRef.nativeElement.focus();
    }, 200);
  }

  saveExchange() {
    if (this.currentCourse === this.adminService.currentCourse.value) {
      this.isAccessChangeExchange = true;
      return;
    }
    this.isAccessChangeExchange = true;
    this.ntf.showLoader();

    let body = {
      actualDate: Date.parse(new Date().toString()) / 1000,
      currency: this.adminService.USD,
      value: this.adminService.currentCourse.value,
      // typeof this.adminService.currentCourse.value === 'number'
      //   ? this.adminService.currentCourse.value
      //   : +this.adminService.currentCourse.value.split('=')[1],
    };

    this.adminService.saveCurrencyCoucse(body).subscribe({
      next: (data) => {
        this.getCurrentCourse(this.adminService.USD.id);
        setTimeout(() => {
          this.ntf.hideLoader();
          this.ntf.isConfirmed(
            "Курс доллара в " +
              `${this.settingsService.siteSettings.mainCurrencyName}`,
            "Успешно сохранен",
            "success"
          );
        }, 500);
      },
      error: (e) => {
        this.ntf.hideLoader();
        console.error(e);
      },
    });
  }

  getCurrentCourse(id: number) {
    this.adminService.getCurrentCource(id).subscribe({
      next: (data) => {
        if (data) {
          this.adminService.currentCourse = data;
        }
      },
      error: (e) => {
        console.error(e);
      },
    });
  }
}
