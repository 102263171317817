import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Settings } from "../models/base.model";
import { ApiConfig } from "../../../api.config";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  private _siteSettings$: BehaviorSubject<Settings> =
    new BehaviorSubject<Settings>({} as Settings);
  private _siteSettings: Settings;

  get siteSettings(): Settings {
    return this._siteSettings;
  }

  constructor(private httpClient: HttpClient) {
    this.getSettings();
  }

  get siteSettings$(): Observable<Settings> {
    return this._siteSettings$.asObservable();
  }

  set siteSettings(value: Settings) {
    this._siteSettings$.next(value);
    this._siteSettings = value;
  }

  getSettings(): void {
    this.httpClient
      .get<Settings>(`${ApiConfig.api}/site-settings`)
      .subscribe((settings) => {
        this.siteSettings = settings;
      });
  }

  updateSettings(data: Settings): Observable<number> {
    return this.httpClient.put<number>(`${ApiConfig.api}/site-settings`, data);
  }
}
